<template>
    <div>
      <PageLoader v-bind:storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-layout wrap justify-center style="background-color: #eefff2">
        <v-flex xs11 sm10 text-left pt-10 pb-4>
          <span
            style="font-family: poppinsmedium; font-size: 25px; color: #000000"
            >My Orders</span
          >
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
            <v-layout wrap justify-center py-5 v-if="order.length > 0">
          <v-flex xs11 sm10>
            <v-layout wrap justify-center>
              <v-flex xs12 py-4 v-for="(item, i) in order" :key="i">
                <v-card outlined>
                  <v-layout wrap justify-center pa-4>
                    <v-flex xs12 sm4 md1 pt-4>
                      <v-img
                        height="100px"
                        width="110px"
                        :src="mediaURL + item.courseId.frontImage"
                      ></v-img>
                    </v-flex>
                    <v-flex xs12 sm7 md7 pa-4 text-left>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="
                              color: #7f7f7f;
                              font-family: poppinsregular;
                              font-size: 13px;
                            "
                            >Courses | {{ item.courseId.category.name }}</span
                          >
                        </v-flex>
                        <v-flex xs12 pt-1>
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 14px;
                              color: #000000;
                            "
                            >{{ item.courseId.courseName }}</span
                          >
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <span
                            style="
                              font-family: poppinsmedium;
                              font-size: 12px;
                              color: #000000;
                            "
                            v-html="item.courseId.description"
                          ></span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4 sm2 md1>
                      <v-layout wrap fill-height py-5>
                        <v-flex xs12>
                          <v-divider vertical></v-divider>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4 sm3 md3 pt-3 tex-center>
                      <v-btn color="#3CB043">
                        <span
                          style="
                            font-family: opensanssemibold;
                            font-size: 14px;
                            color: #fff;
                            text-transform: none;
                          "
                          >View Details</span
                        ></v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-else>
          <v-flex xs12 py-16 text-center>
            <span style="font-family: poppinsbold; font-size: 25px"
              >No Data Found</span
            >
          </v-flex>
        </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        user: {},
        home: [],
        fab: true,
        slideNo: 0,
        slideInterval: 4000,
        order: [],
      };
    },
    beforeMount() {
      this.getData();
    },
  
    methods: {
      getData() {
        this.appLoading = true;
        axios({
          url: "/purchase/list",
          method: "POST",
          headers: {
          token: localStorage.getItem("token"),
        },
        })
          .then((response) => {
            this.appLoading = false;
            this.order = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>